var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_box" }),
    _c(
      "div",
      { staticClass: "centerbox" },
      [
        _c("headbox", {
          attrs: {
            title: "商城",
            bgColor: "#39394D",
            isborder: "true",
            isbackPath: true,
            path: "pricing"
          }
        }),
        _c("img", {
          staticClass: "image",
          attrs: { src: require("@/assets/images/pic_details_top2@2x.png") }
        }),
        _c("div", { staticClass: "topcenter" }, [
          _c("div", { staticClass: "goodsInfo" }, [
            _c("h1", [_vm._v("足金9999投资黄金")]),
            _c("div", { staticClass: "weightSelect" }, [
              _c(
                "div",
                {
                  staticClass: "weight",
                  class: { redBg: _vm.weight === 10 },
                  on: {
                    click: function($event) {
                      return _vm.selectWeight(10)
                    }
                  }
                },
                [_vm._v(" 10g ")]
              ),
              _c(
                "div",
                {
                  staticClass: "weight",
                  class: { redBg: _vm.weight === 20 },
                  on: {
                    click: function($event) {
                      return _vm.selectWeight(20)
                    }
                  }
                },
                [_vm._v(" 20g ")]
              ),
              _c(
                "div",
                {
                  staticClass: "weight",
                  class: { redBg: _vm.weight === 30 },
                  on: {
                    click: function($event) {
                      return _vm.selectWeight(30)
                    }
                  }
                },
                [_vm._v(" 30g ")]
              ),
              _c(
                "div",
                {
                  staticClass: "weight",
                  class: { redBg: _vm.weight === 50 },
                  on: {
                    click: function($event) {
                      return _vm.selectWeight(50)
                    }
                  }
                },
                [_vm._v(" 50g ")]
              ),
              _c(
                "div",
                {
                  staticClass: "weight",
                  class: { redBg: _vm.weight === 100 },
                  on: {
                    click: function($event) {
                      return _vm.selectWeight(100)
                    }
                  }
                },
                [_vm._v(" 100g ")]
              )
            ])
          ]),
          _c("div", { staticClass: "money" }, [
            _c("div", { staticClass: "moneyBox" }, [
              _c("div", { staticClass: "depositText" }, [_vm._v("价格")]),
              _c(
                "div",
                { staticClass: "deposit", staticStyle: { color: "#FF2D2E" } },
                [_vm._v(_vm._s(((_vm.pricebox + 1) * _vm.weight).toFixed(2)))]
              )
            ]),
            _c("div", { staticClass: "moneyBox" }, [
              _c("div", { staticClass: "depositText" }, [_vm._v("锁价定金")]),
              _c(
                "div",
                { staticClass: "deposit", staticStyle: { color: "#FF2D2E" } },
                [_vm._v(_vm._s(_vm.countMumber))]
              )
            ]),
            _c("div", { staticClass: "moneyBox" }, [
              _c("div", { staticClass: "depositText" }, [_vm._v("可用押金")]),
              _c(
                "div",
                { staticClass: "deposit", staticStyle: { color: "#FF2D2E" } },
                [_vm._v(_vm._s(_vm.freeEarnestMoney))]
              )
            ])
          ]),
          _vm._m(0)
        ]),
        _c(
          "footer",
          {
            on: {
              click: function($event) {
                return _vm.postData("showpop")
              }
            }
          },
          [_vm._v(" 立即购买 ")]
        ),
        _c(
          "van-dialog",
          {
            model: {
              value: _vm.showorder,
              callback: function($$v) {
                _vm.showorder = $$v
              },
              expression: "showorder"
            }
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("订单确认")]),
            _c("div", { staticClass: "confirmCenterbox_dialog" }, [
              _c("div", { staticClass: "flex orderInfo" }, [
                _c("div", { staticClass: "orderCol" }, [_vm._v("重量")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.weight) + "g")])
              ]),
              _c("div", { staticClass: "flex orderInfo" }, [
                _c("div", { staticClass: "orderCol" }, [_vm._v("金额")]),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticStyle: { color: "#FF4B71" } }, [
                    _vm._v(_vm._s(((_vm.pricebox + 1) * _vm.weight).toFixed(2)))
                  ]),
                  _vm._v(" 元")
                ])
              ]),
              _c("div", { staticClass: "flex orderInfo" }, [
                _c("div", { staticClass: "orderCol" }, [_vm._v("押金")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.countMumber) + "元")])
              ])
            ]),
            _c("div", { staticClass: "autograph" }, [
              _c("div", { staticClass: "autographText" }, [_vm._v("手写签名")]),
              _c("div", { staticClass: "autographBox" }, [
                _vm.img
                  ? _c("img", { attrs: { src: _vm.img, alt: "" } })
                  : _vm._e()
              ])
            ]),
            _c("div", { staticClass: "flex popbtn" }, [
              _c(
                "div",
                { staticClass: "gocer closebtn", on: { click: _vm.closepop } },
                [_vm._v("取消")]
              ),
              _c(
                "div",
                {
                  staticClass: "gocer confirmbtn",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.postData("postDataf")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ]),
            _c(
              "div",
              { staticClass: "Loading" },
              [
                _vm.Loading
                  ? _c("van-loading", { attrs: { size: "30" } })
                  : _vm._e()
              ],
              1
            )
          ]
        ),
        _c(
          "van-dialog",
          {
            staticClass: "ceringModel",
            model: {
              value: _vm.ceringModel,
              callback: function($$v) {
                _vm.ceringModel = $$v
              },
              expression: "ceringModel"
            }
          },
          [
            _c("div", { staticClass: "title" }, [_vm._v("提示")]),
            _c("div", { staticClass: "centerbox_dialog" }, [
              _vm._v(_vm._s(_vm.stopTrading))
            ]),
            _c(
              "div",
              {
                staticClass: "confirm",
                on: {
                  click: function($event) {
                    _vm.ceringModel = false
                  }
                }
              },
              [_vm._v("确定")]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "goodsDetail" }, [
      _c("h1", [_vm._v("商品详情")]),
      _c("img", {
        attrs: { src: require("@/assets/images/Group 1026@2x.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }