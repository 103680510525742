<template>
  <!--  商城-->
  <div>
    <div class="bg_box"></div>
    <div class="centerbox">
      <headbox
        title="商城"
        bgColor="#39394D"
        isborder="true"
        :isbackPath="true"
        path="pricing"
      ></headbox>
      <img src="@/assets/images/pic_details_top2@2x.png" class="image">
      <!-- 商品信息 -->
      <div class="topcenter">
        <div class="goodsInfo">
          <h1>足金9999投资黄金</h1>
<!--          <div class="condition">9999</div>
          <div class="price">
            <p class="priceText">价格</p>
            <p class="priceNumber">{{(pricebox*100).toFixed(2)}}元</p>
            <button @click="goBuy">立即购买</button>
          </div>-->
          <div class="weightSelect">
            <div class="weight" :class="{ redBg: weight === 10}" @click="selectWeight(10)">
              10g
            </div>
            <div class="weight" :class="{ redBg: weight === 20}" @click="selectWeight(20)">
              20g
            </div>
            <div class="weight" :class="{ redBg: weight === 30}" @click="selectWeight(30)">
              30g
            </div>
            <div class="weight" :class="{ redBg: weight === 50}" @click="selectWeight(50)">
              50g
            </div>
            <div class="weight" :class="{ redBg: weight === 100}" @click="selectWeight(100)">
              100g
            </div>
          </div>
        </div>
        <div class="money">
          <div class="moneyBox">
            <div class="depositText">价格</div>
            <div class="deposit" style="color: #FF2D2E">{{((pricebox+1)*weight).toFixed(2)}}</div>
          </div>
          <div class="moneyBox">
            <div class="depositText">锁价定金</div>
            <div class="deposit" style="color: #FF2D2E">{{ countMumber }}</div>
          </div>
          <div class="moneyBox">
            <div class="depositText">可用押金</div>
            <div class="deposit" style="color: #FF2D2E">{{ freeEarnestMoney }}</div>
          </div>
        </div>

        <div class="goodsDetail">
          <h1>商品详情</h1>
          <img src="@/assets/images/Group 1026@2x.png" alt="">
        </div>

      </div>
      <footer @click="postData('showpop')">
        立即购买
      </footer>
      <!-- 定价确认 -->
      <van-dialog v-model="showorder">
        <div class="title">订单确认</div>
        <div class="confirmCenterbox_dialog">
          <div class="flex orderInfo">  <div class="orderCol">重量</div>  <div>{{weight}}g</div></div>
          <div class="flex orderInfo">   <div class="orderCol">金额</div>  <div> <span style="color:#FF4B71">{{((pricebox+1)*weight).toFixed(2)}}</span> 元</div></div>
          <div class="flex orderInfo">   <div class="orderCol">押金</div>  <div>{{countMumber}}元</div></div>
        </div>
        <!-- <div class="text">温馨提示:{{buyTips}}.</div> -->
        <div class="autograph">
          <div class="autographText">手写签名</div>
          <div class="autographBox">
            <img  v-if="img" :src="img" alt="">
          </div>
        </div>
        <div class="flex popbtn">
          <div class="gocer closebtn" @click="closepop">取消</div>
          <div class="gocer confirmbtn" @click.stop="postData('postDataf')">确定</div>
        </div>
        <div class="Loading"><van-loading  v-if="Loading"  size="30"  /></div>

      </van-dialog>
      <van-dialog v-model="ceringModel" class="ceringModel">
        <div class="title">提示</div>
        <div class="centerbox_dialog">{{stopTrading}}</div>
        <div class="confirm" @click="ceringModel=false">确定</div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import Stomp from "stompjs";
import headbox from "@/components/head";
import * as api from "@/utils/api";
import * as PriceLineChart from "@/utils/api";

export default {
  components:{
    headbox
  },
    data() {
        return {
          // 所有商品数组
          allpro:[],
          // 是否开盘
          trade_: false,
          // 提交时间
          postTime:'',
          // 首次加载的数据
          initDatalist: [],
          firstloadData: true,
          pricebox: '--.--',
          anotherPricebox: '--.--',
          ceringModel: false,
          weight: 10,
          showorder: false,
          // 防止重复提交
          isclick: false,
          // 第二次重复提交
          secondClick: false,
          // 选择的产品key
          productkey: 'huangjin99_99',
          // 预估金额
          countMumber:'0',
          // 可用定金
          freeEarnestMoney:'',
          img: '',
          depositProportion: 30,
          stopTrading:'', // 停盘说明
          lastTime: null, //最后一次点击的时间
          currentTime: null, //当前点击的时间
          timeOut: 30 * 1000, //设置超时时间： 30s
          Loading:false,
        };
    },
  mounted() {
    this.connect();
  },
  destroyed() {
    clearTimeout(this.setInterval)
    this.client.disconnect(function() {
    })
  },
  watch: {
    allpro: function() {
      this.updatearr('黄金99_99')
    },
  },
  async created() {
    const res = await PriceLineChart.getDefaultExplain({ title: "" });
    res.forEach(item => {
      if (item.title === "stopTradingDescription") {
        this.stopTrading = item.content;
      }
    });
    // 查询可用定金接口
    api.getAvailableMoney().then(res=>{
      this.freeEarnestMoney = res.freeEarnestMoney
    });
    const user = JSON.parse(localStorage.getItem('userinfo'))
    this.depositProportion = user.depositDetail.curBuy.policy
    this.countMumber = ((+this.depositProportion)*(+this.weight))
    this.img = user.sign
    this.showorder = false
    this.lastTime = new Date().getTime();   // 网页第一次打开时，记录当前时间
  },
    methods:{
      connect() {
        this.client = Stomp.over(new WebSocket(process.env.VUE_APP_WS));
        // ---初始化mqtt客户端，并连接mqtt服务
        const headers = {
          login: "username",
          passcode: "password"
        };
        this.client.connect(headers, this.onConnected, this.onFailed);
        this.client.debug = null;
      },
      onConnected() {
        const topic = "/price/all";
        this.client.subscribe(topic, this.responseCallback, this.onFailed);
      },
      responseCallback (frame) {
        this.allpro = []
        const resbody = eval('['+this.unzip(frame.body)+']')[0]
        const parse_res = JSON.parse(JSON.stringify(resbody));
        this.trade_ = resbody.trade;
        this.postTime = resbody.time
        if(this.firstloadData){
          this.initDatalist = resbody
          this.firstloadData = false
          for(const i in this.initDatalist){
            if(this.initDatalist[i]['prices'] != undefined){
              this.allpro.push(this.initDatalist[i]['prices'])
            }
          }
        }else{
          this.initDatalist = this.deepMerge(this.initDatalist,parse_res)
          for(const i in this.initDatalist){
            if(this.initDatalist[i]['prices'] != undefined){
              this.allpro.push(this.initDatalist[i]['prices'])
            }
          }
        }
        // this.loadingtrue = true
      },
      unzip(b64Data){
        let strData   = atob(b64Data);
        const charData  = strData.split('').map(function(x){return x.charCodeAt(0);});
        const binData   = new Uint8Array(charData);
        const data    = pako.inflate(binData);
        strData   = String.fromCharCode.apply(null, new Uint16Array(data));
        return decodeURIComponent(escape(strData));
      },
      deepMerge(...objs) {
        const than = this
        const result = Object.create(null)
        objs.forEach(obj => {
          if (obj) {
            Object.keys(obj).forEach(key => {
              const val = obj[key]
              if (than.isPlainObject(val)) {
                // 递归
                if (than.isPlainObject(result[key])) {
                  result[key] = than.deepMerge(result[key], val)
                } else {
                  result[key] = than.deepMerge(val)
                }
              } else {
                //  数组也要重新赋值  不然依然会引用到其他的
                if (Array.isArray(val)) {
                  result[key] = [...val]
                } else {
                  result[key] = val
                }
              }
            })
          }
        })
        return result
      },
      isPlainObject(val) {
        const toString = Object.prototype.toString
        return toString.call(val) === '[object Object]'
      },
      // 更新数组
      updatearr(name){
        if(name == '黄金'){
          this.pricenew('huangjin9999')
          this.productkey = 'huangjin9999'
        }else if(name == '黄金金条999'){
          this.pricenew('huangjin999jt')
          this.productkey = 'huangjin999jt'
        }else if(name == '黄金首饰999'){
          this.pricenew('huangjin999ss')
          this.productkey = 'huangjin999ss'
        }else if(name == '黄金18K 750'){
          this.pricenew('huangjin750')
          this.productkey = 'huangjin750'
        }else if(name == '黄金18K 750'){
          this.pricenew('huangjin750')
          this.productkey = 'huangjin750'
        }else if(name == '商城金条'){
          this.pricenew('market_huangjin')
          this.productkey = 'huangjin9999'
        }else if(name == '铂金'){
          this.pricenew('bojin9996')
          this.productkey = 'bojin9996'
        }else if(name == '铂金首饰999'){
          this.pricenew('bojin999')
          this.productkey = 'bojin999'
        }else if(name == '铂金首饰990'){
          this.pricenew('bojin900')
          this.productkey = 'bojin900'
        }else if(name == '铂金首饰950'){
          this.pricenew('bojin950')
          this.productkey = 'bojin950'
        }else if(name == '铂金(无法确定)'){
          this.pricenew('bojin9996')
          this.productkey = 'bojin9996'
        }else if(name == '钯金'){
          this.pricenew('bajin9996')
          this.productkey = 'bajin9996'
        }else if(name == '钯金首饰999'){
          this.pricenew('bajin999')
          this.productkey = 'bajin999'
          this.pricenew('bajin990')
          this.productkey = 'bajin990'
        }else if(name == '钯金首饰950'){
          this.pricenew('bajin950')
          this.productkey = 'bajin950'
          this.pricenew('bajin9996')
          this.productkey = 'bajin9996'
        }else if(name == '白银'){
          this.pricenew('baiyin9999')
          this.productkey = 'baiyin9999'
        }else if(name == '白银首饰999'){
          this.pricenew('baiyin999')
          this.productkey = 'baiyin999'
        }else if(name == '白银首饰990'){
          this.pricenew('baiyin990')
          this.productkey = 'baiyin990'
        }else if(name == '白银首饰925'){
          this.pricenew('baiyin925')
          this.productkey = 'baiyin925'
        }else if(name == '白银(无法确定)'){
          this.pricenew('baiyin9999')
          this.productkey = 'baiyin9999'
        } else if(name == '黄金99_99') {
          this.pricenew('huangjin99_99')
          this.productkey = 'huangjin99_99'
        }
      },
      // 价格变动更新视图
      pricenew(name){
        this.allpro.find((x)=>{
          if(x[name]){
            this.pricebox = x[name]['xiaoshou']
            this.anotherPricebox = x[name]['huigou']
          }
        })
      },
      selectWeight(weight) {
        this.weight = weight
        this.countMumber = ((+this.depositProportion)*(+this.weight))
      },
      closepop(){
        this.showorder=false
        this.isclick=false
      },
      postData(type) {
        //判断上次最后一次点击的时间和这次点击的时间间隔是否大于10分钟
          this.lastTime = new Date().getTime(); //如果在30钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
          if (type == 'postDataf') {
            this.currentTime = new Date().getTime(); // 记录这次点击的时间
            if (this.currentTime - this.lastTime > this.timeOut) {
              // 这里写状态已过期后执行的操作
              this.$toast({
                type: "fail",
                message: "当前页面停留时间过长",
                forbidClick: true,
                onClose: () => {
                  this.showorder = false;
                  this.lastTime = new Date().getTime();
                }
              });
            }
            if (this.secondClick) {
              setTimeout(() => {
                this.secondClick = false;
              }, 1000)
              return false
            }
            this.secondClick = true
            const postData = {
              "addr": '',
              "lat": 0,
              "lng": 0,
              "order": [
                {
                  "key": this.productkey,
                  "weight": this.weight
                }
              ],
              "time": this.postTime,
              "businessType": "0",
              "isMall": 1,
            }
            api.buygold(postData).then(res => {
              this.weight = 10
              this.countMumber = ((+this.depositProportion)*(+this.weight))
              this.secondClick = false
              this.showorder = false
              this.$router.push({ name: 'successpage', query: { type: 'buy' } })
            }).catch(err => {

            })
          } else {
            if (this.isclick) {
              setTimeout(() => {
                this.isclick = false;
              }, 1000)
              return false
            }
            if (!this.trade_) {
              this.ceringModel = true
              return false
            }
            this.isclick = true
            if (!Number(this.weight)) {
              return this.$toast('克重不能为空'), !1
            }
            if (this.countMumber > this.freeEarnestMoney) {
              return this.$toast('定金不足!请充值'), !1
            }
            this.showorder = true
            this.isclick = false
          }
      },
      isDo() {
        this.lastTime = new Date().getTime(); //如果在30钟内点击，则把这次点击的时间记录覆盖掉之前存的最后一次点击的时间
      },
    }

}
</script>

<style lang="scss" scoped="true">
@import "@/assets/styles/index.sass";
::v-deep{
  .van-nav-bar{
    height: 96px;
    .van-nav-bar__content{
      line-height: 96px;
      height: 96px;
      background-image: linear-gradient(to right, #ffffff , #ffffff,#ffffff);
      .van-nav-bar__title{
        font-size: 36px;
        color: #1a1a1a;
      }
    }
  }
  .van-dialog__content{
    text-align: center;
  }
}


.shopping{
  background: #f7f7f7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.topcenter {
  position: relative;
}
.goodsInfo{
  background-color: #ffffff;
  margin-top: 10px;
  h1{
    font-size: 40px;
    font-weight: bolder;
    padding: 0 20px;
  }
  .condition{
    width: 90px;
    padding: 2px 2px;
    margin: 20px 20px;
    border: 1px solid #ff2d2e;
    color: #ff2d2e;
    font-size: 32px;
    text-align: center;
    border-radius: 20px;
  }
  .price{
    display: flex;
    margin: 20px 20px;
    padding-bottom: 20px;
    .priceText{
      color: #d5d5d5;
      font-size: 30px;
      margin-top: 10px;
    }
    .priceNumber{
      color: #c8695e;
      font-weight: bold;
      font-size: 40px;
      margin-left: 20px;
    }
    button{
      width: 200px;
      height: 60px;
      margin-left: auto;
      background: #39394D;
      margin-right: 20px;
      border: 0;
      border-radius: 50px;
      color: #f6d0ab;
      font-size: 36px;
    }
  }
  .weightSelect {
    margin: 20px 20px 0;
    display: flex;
    //justify-content: space-between;
    flex-flow: wrap;
  }
  .weight {
    width: 180px;
    height: 64px;
    line-height: 64px;
    color: #ffffff;
    background: #c1c1c1;
    text-align: center;
    font-size: 28px;
    margin: 0 20px 20px;
    border-radius: 10px;
  }
  .redBg {
    background: #FF2D2E;
  }
}
.goodsNextInfo{
  display: flex;
  margin-top: 10px;
  width: 100%;
  background-color: #ffffff;
  font-size: 32px;
  color: #777777;
  p:first-child{
    margin: 20px 0 20px 20px;
  }
  p {
    margin: 20px 0 20px 140px;
  }
}
.money {
  margin: 0 40px 30px;
  .moneyBox {
    display: flex;
    justify-content: space-between;
    color: #39394D;
    font-size: 28px;
    padding: 10px 0;
    border-bottom: 1px solid #c1c1c1;
    &:last-child {
      border-bottom: none
    }
  }
}
.goodsDetail{
  padding-bottom: 110px;
  background-color: #ffffff;
  text-align: center;
  h1{
    font-size: 40px;
    font-weight: bolder;
    padding: 20px 20px;
    text-align: left;
  }
  img{
    width: 95%;
  }
}
::v-deep {
  .van-nav-bar__right{
    margin-right: 20px;
    color:#ffffff;
    flex-direction: column;//修改主轴方向
    //flex-wrap: wrap;//开启自动换行
    .van-nav-bar_right_text{
      font-size: 25px;
      height: 24px;
      margin: -20px 0 20px 0;
    }
    .van-nav-bar_right_price {
      font-size: 35px;
      height: 24px;
    }
  }
}
.title {
  font-size: 30px;
  text-align: center;
  margin: 20px 0;
}
.centerbox_dialog {
  margin-top: 10px ;
  text-align: center;
  font-size: 30px;
}
::v-deep .van-dialog{
  width: 90%;
  padding: 0 40px 30px;
  .title{
    color: #242424;

  }
}
::v-deep .van-button{
  display: none
}
::v-deep.van-button {
  display: none;
}
.gocer{
  margin: 0 auto;
  width: 280px;
  height: 72px;
  font-size: 28px;
  line-height: 72px;
  margin-top: 30px;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  background-color: #FF2D2E;
}
.bg_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
footer{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100px;
  background-color: #39394D;
  font-size: 32px;
  text-align: center;
  line-height: 100px;
  color: #f6d0ab;
  text-align: center;
}
.confirmCenterbox_dialog{
  text-align: left;
  padding-bottom: 30px;
  font-size: 28px;
  border-bottom: 1px solid #E9E9E9;
}
.orderInfo{
  padding: 12px 0;
  .orderCol{
    color: #999999;
  }
}
.autograph{
  width: 100%;
  text-align: center;
  margin-top: 20px;
  box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
  .autographText{
    width: 100%;
    height: 56px;
    line-height: 56px;
    color: #242424;
    background-color: #5473FF;
    font-size: 24px;
  }
  .autographBox{
    height: 200px;
    border: 1px solid #E9E9E9;
    img{
      width: 200px;
      height: 100%;
      transform:rotate(270deg);
    }
  }
}
.popbtn{
  margin-top: 25px;
  div{
    margin-top: 5px!important;
    border-top: none!important;
    width: 210px;
    text-align: center;
    border-radius: 50px;
  }
}
.flex{
  display: flex;
  justify-content: space-between;
}
.ceringModel{
  width: 74%;
  .centerbox_dialog{
    padding: 0 0 30px !important;
    border-bottom: 1px solid #E9E9E9;
  }
  .confirm{
    margin: 30px 0  0;
    text-align: center;
    height: 40px;
    color: #39394D;
    margin-top: 30px;
    font-size: 28px;
    line-height: 40px;
  }
}
.closebtn{
  background-color: #F1F3F7;
  color: #39394D;
}
.confirmbtn{
  background-color:#39394D ;
  color: #f6d0ab;
}
</style>
